body {
  overflow-x: hidden;
}

html {
  overflow-x: hidden;
}

#root {
  overflow-x: hidden;
}

/*
  Fonts
*/
@import url('https://fonts.googleapis.com/css2?family=Heebo&family=Raleway:wght@200&display=swap');

/*
  Scroll-Bar
*/

/* width */
::-webkit-scrollbar {
  width: 7px;
  overflow-x: hidden;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #009688;
}